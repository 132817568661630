import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "benWood",
  "displayAuthorHeader": false,
  "categories": ["case studies"],
  "date": "2022-05-31",
  "path": "/blog/angi-case-study",
  "summary": "We partnered with Angi to upgrade their monolith to the latest version of Ruby and Rails with zero downtime, and without disrupting ongoing feature development.",
  "title": "Case Study: Angi",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@benjaminwood"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Executive Summary`}</h2>
    <p>{`Founded in 1995, Angi (formerly Angie’s List) connects individuals looking for home improvement, repair and household services with home service professionals. At the center of the Angi Services business is a Ruby on Rails-based web application internally known as "Handybook". Angi engaged Hint's services to upgrade Handybook from Rails 4.1 and Ruby 2.3 to the latest versions of Ruby and Rails.`}</p>
    <h2>{`Challenges`}</h2>
    <p>{`Angi is experiencing accelerated growth. Keeping application dependencies up-to-date is always important, but it's absolutely essential in today's developer market. Attracting and retaining top talent is difficult already; add an outdated stack into the mix, and it's nearly impossible. Security & compliance risks can be mitigated by patching old dependencies, but there is no shortcut to improving developer happiness. With this in mind, Angi chose Hint as its partner to establish a process by which major dependency upgrades can be performed safely & continuously.`}</p>
    <h2>{`How we helped`}</h2>
    <p>{`Angi's services are in high demand, and Handybook is an essential application. Featuring deep integration with third-party services and 40+ microservices, Handybook has a lot of moving parts. Aggressive caching, database sharding, and horizontal scaling are all important factors allowing the application to meet demand.`}</p>
    <h3>{`Upgrade Manifesto`}</h3>
    <p>{`Before starting on the upgrade, we set forth the following upgrade manifesto.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Minimize WIP`}</strong>{`. Upgrading Rails in a large, very active codebase is a risky business. Falling behind is easy, and if you're not careful, you may find yourself in merge hell. Practically speaking, this means no long-running branches. Instead, all changes must be PR'd to the main branch and be merged swiftly.`}</li>
      <li parentName="ul"><strong parentName="li">{`Incremental everything`}</strong>{`. No task is too small to deserve asking the question, "how can we break this up?". We minimize WIP and reduce business risk by taking many small, incremental steps. Put in practice, this means changes for compatibility are made in the smallest possible units, and when deploying changes into production, we perform a canary deployment, starting at 1% traffic and ramping up slowly.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use data to drive decision making`}</strong>{`. Sometimes it isn’t possible to determine the correct solution for a given challenge by static analysis, even with excellent git history. In these situations, it's tempting to guess. But don’t do it; it's a trap! Instead, use dynamic analysis (often in production). Gather more information, and make decisions based on fact, not conjecture.`}</li>
      <li parentName="ul"><strong parentName="li">{`Over-communicate`}</strong>{`. We'll be making significant sweeping changes (incrementally) to the entire codebase. If something goes wrong and the symptom is outside our purview, we want to know about it. By continuously communicating project status, every member of the organization becomes a secret agent, reporting helpful information, resulting in a smoother upgrade.`}</li>
    </ul>
    <h3>{`Process`}</h3>
    <h4>{`Dual Boot`}</h4>
    <p>{`In the spirit of "minimizing WIP" and "incremental everything", we need a way for all compatibility changes to flow to the main branch. The solution is to dual-boot the application with two different sets of dependencies co-existing on the main branch together. Dual-booting a Rails application is a well-trodden path at this point, and to make the process easier, we took advantage of Shopify's excellent bootboot gem.`}</p>
    <p>{`With bootboot installed, you can insert a condition like the following into your Gemfile:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"DEPENDENCIES_NEXT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"rails"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"~> 6.1.0"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
  gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"rails"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"~> 6.0.0"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`In each stage of the upgrade (Rails 4.1 -> 4.2, 4.2 -> 5.0, and so on), we begin with bundling the new/next set of dependencies in a Gemfile_next.lock. In each step, some dependencies can be upgraded alongside the current/non-next set of dependencies (i.e. Gemfile.lock). These dependencies are upgraded in advance and removed from the Gemfile_next.lock equation.`}</p>
    <h4>{`Changes for compatibility`}</h4>
    <p>{`Once the main branch has a Gemfile_next.lock with the target dependencies, our focus turns to compatibility. The goal here is to obtain green CI results when the suite is run against both sets of dependencies. Note that only upgrade-related branches run the test suite against the "next" dependencies at this stage.`}</p>
    <p>{`Each compatibility change targets the main branch. Ideally, our changes are compatible with both the current and next set of dependencies "out of the box." When changes are incompatible, we consider making them compatible by backporting functionality from the new dependencies. If the backport path is not feasible, we add a condition. Here’s an example where sprockets changed the API for registering an engine:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` dependencies_next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`application`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`assets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`configure `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`env`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
    env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`register_engine `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.es6'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BabelTranspile`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`application`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`assets`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`register_engine `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.es6'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BabelTranspile`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`This stage is where the bulk of the upgrade work takes place. Many small PR's bring compatibility with the new/next set of dependencies until, eventually, we have a green build on CI with the suite running under both sets of dependencies.`}</p>
    <h4>{`Green CI, require compatibility on all branches`}</h4>
    <p>{`Once the test suite is green when run under both sets of dependencies, it's time to "lock it in". At this phase, we enable dual-boot CI on all branches. This mandates all developers write code in a way that is compatible with both sets of dependencies. Changes are not approved to be merged to the main branch unless they are compatible with both sets of dependencies.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "73.61963190184049%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9UlEQVQ4y5WTS2/TQBRG+7cJbVSlFStoKh4r/gNVs0AC1EQJZQHivUVCAlGn1LFnPO+H4w/dCWnrpASwdDSZ2PfkuzfjLQD4/vUb7uz30d29j9vbB+h07iVude6m/d7+o0Sv9wC9vYfY6R5ie6ePbrePw4PHUNqQBk3TYIs+5Oc5xi9eYXjyBoPBSxwfj3F0NMJgMMHzZ68xGr3H6elnjMcfMZl8wnD4Ln13MvqAJ0/fwjnfFjrv4axEdBWcN4jRomkimnlACBabri9FJFNbqJRClk2RZRnO0jq92p9lEEKiqkRCCJESEcEHoA6Yz+dtodYajDGUSiEXDiFE1HVEjAucc1d4D38NEq8JjTHgnKGSCpUQ0MZCGgfrXKv4T9wonM0KVLxK7UtlILSFtQ51XW+EOlgTUiskoxnFEBC8h9Yq/VAIIRXRSmloXWVNSMNlusIPPkUhSxSagWkBxnmSUhIqtNZeipfcKKQbQguUhoPrCsIpMMkwK2bpX6UCKlwmXk263rL3MNqgjoskMURoTUekSpKUJvhWwo1Cn2amWw9LyVGWBaSQcNalOVrzH8LVdgrNkfMCpWDQzkB7A230P7bsXDoulwMPHlN5gYz9xDm7QC4L5KoEN1Uax1/PIYlISDNaoq35jYYyC4w1rWcICtNce5d/AYdgdTm5zsbAAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "handybook-ci",
        "title": "handybook-ci",
        "src": "/static/f1a84349e923f3e7bdd830fe1c1582f8/a6d36/handybook-ci.png",
        "srcSet": ["/static/f1a84349e923f3e7bdd830fe1c1582f8/222b7/handybook-ci.png 163w", "/static/f1a84349e923f3e7bdd830fe1c1582f8/ff46a/handybook-ci.png 325w", "/static/f1a84349e923f3e7bdd830fe1c1582f8/a6d36/handybook-ci.png 650w", "/static/f1a84349e923f3e7bdd830fe1c1582f8/e548f/handybook-ci.png 975w", "/static/f1a84349e923f3e7bdd830fe1c1582f8/3c492/handybook-ci.png 1300w", "/static/f1a84349e923f3e7bdd830fe1c1582f8/821da/handybook-ci.png 2200w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4>{`Smoke test in a production-like environment`}</h4>
    <p>{`The final stage is QA. Angi uses Kubernetes namespaces to spin up production-like environments for testing purposes. Testing typically starts with a sanity check/smoke test by upgrade engineers. After that, a QA specialist puts the upgraded application through its paces.`}</p>
    <p>{`When a bug is discovered in this phase, it is fixed on the testing branch and re-deployed for further testing. This cycle repeats until no known issues remain.`}</p>
    <h4>{`Canary Deployment`}</h4>
    <p>{`Once all the testing & QA boxes are checked, it's time to deploy, but not all at once. In the spirit of "incremental everything", we begin rolling out the upgraded application alongside the current version. At Angi, we accomplish this with weighted DNS in AWS Route53. We send 1% of production traffic to Kubernetes pods running the upgraded application, then monitor and see what happens. If everything checks out, we increase to 5% and continue in small steps to 100%. If an issue is discovered, we dial traffic back (0% to upgraded instances), fix the problem, and start back at 1%. This phase takes time, but it's invaluable.`}</p>
    <p>{`We utilized a Kibana dashboard to monitor the rollout. In the picture below, you can see roughly 1% of web traffic is targeting handybook-next, while all sidekiq jobs are still being processed by non-next pods.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.44171779141104%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEElEQVQoz22Ta08TQRSG+eeaqFE0+lGRhmpbih8AgWC5GBPhA0Kii9Bta7rbbUuXFmjB3W4v270+ZqY0WHSTd98z55x5MpfMDIDT7ZIvFlEKKscFlaKuodVr6PUaDdPkvNmkrGkoxVOU4olUqVqmfGZQbzRoNEy6jiNQzERxJIOSfcbr8hoL+icW9BxvtHVy5hFhOK57UUDS2GSuskbC2GC+ssFCdRM76Mt6HMdSMxExhPD9qsjj0yQv1IyUiJdrX8bNUYw96PGqsMTTfIrnaobZfJqXhSw3o67sCcLwFihWGAtgiQc/EzxT01IPTxJ8qH5mTATHHUjQo9MkT9UUT/LvmFXTXI/saaD4iQmtfof9i2P2zxX2TIW9lkLJrjH5/CDg8Eplr6nw1fzBfvOYw7aKG45kPZwA5fl4PpZlc2NbtK+vuex0GLguo8DH8QZSPW+IFwYMvRH94VC6GI98nyAI74AxMXEYo12eka5ska3usGhss2jskDG2yVZ3yRqT3Fgilza25JFcOjdEQUQYRRMgMlG+qJPSc2Qq2xIsPHXrYvx3LPy9nmPJ2MXx+9NblisMYn5dVJnTVkjoH3mrr075/H9yojdpbGB5zn0gRGGIYV6xfFBm9VBn5Zv+j9/PLR9orB9V6Ln+LTC6uxRBH7oeluVj/b6TbQW020NarR6djotlBVN10e/74wsREi/lD5OhEzzrS0ecAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "handybook-dashboard",
        "title": "handybook-dashboard",
        "src": "/static/8b6aac4eb9fb736cc1ff434239121422/a6d36/handybook-dashboard.png",
        "srcSet": ["/static/8b6aac4eb9fb736cc1ff434239121422/222b7/handybook-dashboard.png 163w", "/static/8b6aac4eb9fb736cc1ff434239121422/ff46a/handybook-dashboard.png 325w", "/static/8b6aac4eb9fb736cc1ff434239121422/a6d36/handybook-dashboard.png 650w", "/static/8b6aac4eb9fb736cc1ff434239121422/e548f/handybook-dashboard.png 975w", "/static/8b6aac4eb9fb736cc1ff434239121422/3c492/handybook-dashboard.png 1300w", "/static/8b6aac4eb9fb736cc1ff434239121422/859b0/handybook-dashboard.png 5098w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h4>{`Bake at 100%, strip and repeat`}</h4>
    <p>{`Some issues may not be immediately apparent. Once 100% of production traffic is being routed to the upgraded application, it's time to let it bake. After a day or two of watching graphs & logs, we commit to the upgrade.`}</p>
    <p>{`Committing to the upgrade means copying the contents of Gemfile_next.lock over to Gemfile.lock, freeing up Gemfile_next.lock for the next upgrade step. We also must remove all compatibility backports and dependencies_next? conditions.`}</p>
    <p>{`At this stage, the application has successfully been upgraded to the target dependencies, and we can move on to the next upgrade step!`}</p>
    <h3>{`Challenges`}</h3>
    <p>{`By following the predefined upgrade manifesto & process, we avoided many common pitfalls. However, it's worth calling out some unique challenges that we encountered.`}</p>
    <h4>{`Strong Parameters migration`}</h4>
    <p>{`Strong parameters is a mass-assignment security feature first introduced in Rails 4.0 as a replacement for protected attributes. When we began the upgrade process, Handybook was running Rails 4.1 and had only partially embraced Strong Parameters. Once we reached Rails 4.2, finishing the migration to Strong Parameters was necessary before upgrading to Rails 5.0 where protected attributes are no longer supported.`}</p>
    <p>{`Performing this migration is never easy, and doing so in Handybook was no exception. Therefore, we developed a process (and tools) that allow us to perform the migration systematically, without guessing. At a high level, the process goes like this:`}</p>
    <ul>
      <li parentName="ul">{`Install logging that shows us where unpermitted mass-assignment is being performed.`}</li>
      <li parentName="ul">{`Install moderate_parameters, a gem we developed that shows us what parameters will be filtered out by strong parameters, without actually filtering any parameters out.`}</li>
      <li parentName="ul">{`Use the test environment to resolve all unpermitted mass-assignment & moderate parameter logs.
Take unpermitted mass-assignment & moderate parameter logging into production and resolve remaining issues until the logs are empty or until they contain only known/explicitly ignored entities (parameters that should * not be permitted).`}</li>
      <li parentName="ul">{`Swap calls to moderate parameters for the strong parameters at which time actual filtering occurs.`}</li>
      <li parentName="ul">{`Finally, remove the protected_attributes gem and related code, and the application is running with Strong Parameters!`}</li>
    </ul>
    <h4>{`Complex Ruby objects in persistent data stores`}</h4>
    <p>{`Storing complex Ruby objects in persistent data stores is a mistake that often bites us during upgrades. The problem is, it's hard to know when it'll bite, and it often goes undetected in test suites & QA testing. For the problem to occur, objects must be serialized into persistent storage by the application running one set of dependencies, then read by the application running another set of dependencies. When it "bites," the application fails to deserialize the object, often because of a constant removed or renamed.`}</p>
    <p>{`For example, if you attempt to serialize an ActiveRecord object in Rails 4.2, and deserialize it in Rails 5.0, you will get an ArgumentError like the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`ArgumentError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` undefined `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActiveRecord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ConnectionAdapters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`AbstractMysqlAdapter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`MysqlDateTime`}</span></code></pre></div>
    <p>{`We ran into several of these scenarios while upgrading Handybook. In each case, the solution was to rework the code such that complex objects are not put in persistent data stores. Instead, serialization is performed upfront, and only simple data types are put in persistent storage. When the data is deserialized, we rehydrate those complex Ruby objects, and the code path continues as it did before.`}</p>
    <h4>{`Octopus -> Rails 6.1 multi-database features & Octoball`}</h4>
    <p>{`Another significant migration was caused by changes in Active Record at Rails 6.1 to enable new multi-database features. Specifically, Handybook was using a gem called Octopus to make some database queries using read-replicas, and unfortunately, the gem is not supported beyond Rails 6.0. Adding support for Rails 6.1 to Octopus wasn't feasible because of fundamental assumptions made in its approach, which changed in Rails 6.1.`}</p>
    <p>{`The good news is that Rails 6.1 introduced multi-database features that make much of what a gem like Octopus did obsolete. We were able to migrate Handybook to a gem named Octoball, which is a relatively thin layer on top of Rails 6.1 multi-database implementation offering an Octopus-like API.`}</p>
    <p>{`Getting rid of the Octopus gem was a massive win for the Handybook application as it was the source of many edge-case bugs and unexpected behavior. A bonus was that Octoball usage could also be tested in CI.`}</p>
    <h2>{`Results`}</h2>
    <p>{`We partnered with Angi in their effort to improve developer experience & productivity by investing in the platform. Upgrading a large/busy Rails application is tedious work and it's easy to introduce new tech debt that compounds making future upgrades even more difficult. By following a well-defined process backed by the upgrade manifesto, we achieved an overall reduction in technical debt, leading to a number of positive outcomes.`}</p>
    <p>{`Perhaps the best way to highlight the results is by sharing some inspirational quotes from a few of our favorite people at Angi:`}</p>
    <p><strong parentName="p">{`Angi developers are happy, leading to increased productivity`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`"I think I speak for every long-tenured Angi person when I say... WOW!"`}</p>
      <p parentName="blockquote">{`-`}{` David Olsen `}<br /><small>{` `}{` `}{` `}{`Director, Infrastructure Engineering`}</small></p>
    </blockquote>
    <p><strong parentName="p">{`With an up-to-date stack, it's easier to attract top engineering talent`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`"Handybook is pretty heckin modern if you ask me."`}</p>
      <p parentName="blockquote">{`-`}{` Thomas Johnell `}<br /><small>{` `}{` `}{` `}{`VP, Engineering`}</small></p>
    </blockquote>
    <p><strong parentName="p">{`Angi customer data is safer than ever (and security & compliance can sleep at night)`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`"I for one like software that is not EOL and still receives security updates. Nice work!"`}</p>
      <p parentName="blockquote">{`-`}{` Chris Sansone `}<br /><small>{` `}{` `}{` `}{`Director of Information Security and Risk`}</small></p>
    </blockquote>
    <h2>{`How can we help you?`}</h2>
    <p>{`Interested in finding out how partnering with Hint could help your company achieve similar results? We'd love to talk! `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`Contact us`}</a>{` to get started.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      